<template>
	<AuthLayout>
		<nav aria-label="breadcrumb my-3">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link tag="a" class="breadcrumb-item" :to="{ name: 'retailers' }">
						<span>Retailers</span>
					</router-link>
				</li>
				<li class="breadcrumb-item">Edit Details</li>
				<li class="breadcrumb-item active" aria-current="page" v-if="retailer.id">
					<a>{{ retailer.id }}</a>
				</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-6 offset-3">
				<form class="retailer" @submit.prevent="onSubmit">

					<TextField
						:value="retailer.retailer_account_number"
						@change="event => inputChanged({
							key: 'retailer_account_number',
							value: event
						})"
						label="Account Number" 
						:errors="errors.retailer_account_number"
					/>

					<TextField 
						:value="retailer.company"
						@change="event => inputChanged({
							key: 'company',
							value: event
						})"
						label="Company Name" 
						:errors="errors.company"
					/>

					<TextField 
						:value="retailer.retailer_password"
						@change="event => inputChanged({
							key: 'retailer_password',
							value: event
						})"
						type="password"
						label="New Password" 
						:errors="errors.retailer_password"
					/>

					<TextField 
						:value="retailer.retailer_password_confirmation"
						@change="event => inputChanged({
							key: 'retailer_password_confirmation',
							value: event
						})"
						type="password"
						label="Confirm Password" 
						:errors="errors.retailer_password_confirmation"
					/>

					<div class="form-group">
						<label for="state">Retailer Status</label>
						<select 
							:value="retailer.retailer_status"
							@change="event => inputChanged({
								key: 'retailer_status',
								value: event.target.value
							})"
							id="retailerStatusSelect"
							:class="[{ 'is-invalid': errors.retailer_status }, 'form-control rounded-10']"
						>
							<option value="" disabled selected>
								Please Select
							</option>
							<option value="active">Active</option>
							<option value="inactive">Inactive</option>
						</select>
						<div class="text-danger text-left d-flex mt-1" v-if="errors.retailer_status">
							{{ errors['retailer_status'][0] }}
						</div>
					</div>

					<div class="py-5">
						<Button 
                            type="submit" 
                            color="success" 
                            :loading="loadingFlag || !retailer.id" 
                            block
                        >
                            Save Changes
                        </Button>

						<Button 
                            color="danger" 
                            :loading="loadingFlag || !retailer.id" 
                            block
							@click.native="onCancel"
                        >
                            Cancel Editing
                        </Button>
					</div>
				</form>
			</div>
		</div>
	</AuthLayout>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import swal from 'sweetalert'

	export default {
		name: "UpdateRetailer",

		computed: {
			...mapGetters('retailer', [
				'retailer',
				'errors',
				'loadingFlag'
			])
		},

		methods: {
			...mapActions('retailer', [ 'inputChanged' ]),

			async onSubmit() {
				await this.$store.dispatch('retailer/updateRetailer', {
					onSuccess: () => {
						swal("Retailer Edited Successfully", "All changes made to the Lifebox retailer has been saved and applied. Well done.", "success")
						this.$router.push({ name: 'retailers' })
					},
					onFailed: () => {
						swal("Retailer Editing Failed", "Please fill in the missing / incorrect details and try again.", "error")
					}
				})
			},

			async onCancel() {
				const message = {
					title: 'Are you sure you want to cancel editing this retailer?',
					text: "All changes made to this retailer will not be saved after this confirmation.",
					icon: 'warning',
					buttons: true,
					dangerMode: true
				}

				if (await swal(message)) {
					await this.$store.dispatch('retailer/clearRetailer')
					this.$router.push({ name: 'retailers' })
				}
			},
		},

		async beforeRouteEnter(to, from, next) {
			try {
				const retailerID = to.params.retailerID
				next(async vm => await vm.$store.dispatch('retailer/setRetailer', retailerID))
			} catch (e) {
				next({ name: 'home' })
			}
		},

		async beforeRouteUpdate(to, from, next) {
			try {
				await this.$store.dispatch('retailer/clearRetailer')
				next()
			} catch (e) {
				next({ name: 'home' })
			}
		},

		async beforeRouteLeave(to, from, next) {
			try {
				await this.$store.dispatch('retailer/clearRetailer')
				next()
			} catch (e) {
				next({ name: 'home' })
			}
		}
	}
</script>